<template>
  <div>
    <VRow>
      <VCol>
        <VDataTable
          :items="userChiefs"
          :headers="connectedPeopleHeaders"
          :loading="!chiefs"
        >
          <template #top>
            <VRow class="mb-4">
              <VCol class="d-flex align-center">
                <h3>
                  Руководители
                </h3>
              </VCol>
              <VCol
                cols="auto"
                class="d-flex align-center"
              >
                <VBtn
                  color="primary"
                  :disabled="!selectedStaffPosition"
                  :to="{
                    name : Names.R_ORGSTRUCTURE_POSITION_TO_POSITION,
                    params : {
                      staffPositionId : selectedStaffPosition
                    },
                    query : {
                      staffPositionId : selectedStaffPosition,
                      chief : true
                    }
                  }"
                >
                  <VIcon left>
                    fal fa-plus
                  </VIcon>
                  Добавить руководителя
                </VBtn>
              </VCol>
            </VRow>
          </template>
          <template #item.staffPosition.name="{ item }">
            <RouterLink
              :to="{
                name : Names.R_ORGSTRUCTURE_POSITION_EDIT,
                params : {
                  positionId : item.staffPosition.id
                }
              }"
            >
              {{ item.staffPosition.name }}
            </RouterLink>
          </template>
          <template #item.staffPosition.teamName="{item}">
            <RouterLink
              :to="{
                name : Names.R_ORGSTRUCTURE_COMPANY_TEAM,
                params : {
                  companyId : item.staffPosition.companyId,
                  teamId : item.staffPosition.teamId
                }
              }"
            >
              {{ item.staffPosition.teamName || item.staffPosition.teamDisplayName }}
            </RouterLink>
          </template>
          <template #item.user.fullName="{item}">
            <RouterLink
              v-if="item.user"
              :to="{
                name : Names.R_AUTHENTICATION_USER,
                params : { userId : item.user.id }
              }"
            >
              {{ item.user.fullName }}
            </RouterLink>
          </template>
          <template #item.actions="{item}">
            <VTooltip left>
              <template #activator="{ on, attrs }">
                <VBtn
                  icon
                  small
                  dark
                  color="red"
                  class="mx-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="unlinkStaffPosition(item, false)"
                >
                  <VIcon small>
                    fal fa-trash
                  </VIcon>
                </VBtn>
              </template>

              <span>Удаление</span>
            </VTooltip>
          </template>
        </VDataTable>
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <VDataTable
          :items="userSubordinates"
          :headers="connectedPeopleHeaders"
          :loading="!subordinates"
        >
          <template #top>
            <VRow class="mb-4">
              <VCol class="d-flex align-center">
                <h3>
                  Подчиненные
                </h3>
              </VCol>
              <VCol
                cols="auto"
                class="d-flex align-center"
              >
                <VBtn
                  color="primary"
                  :disabled="!selectedStaffPosition"
                  :to="{
                    name : Names.R_ORGSTRUCTURE_POSITION_TO_POSITION,
                    params : {
                      staffPositionId : selectedStaffPosition
                    },
                    query : {
                      staffPositionId : selectedStaffPosition
                    }
                  }"
                >
                  <VIcon left>
                    fal fa-plus
                  </VIcon>
                  Добавить подчинённого
                </VBtn>
              </VCol>
            </VRow>
          </template>
          <template #item.staffPosition.name="{ item }">
            <RouterLink
              :to="{
                name : Names.R_ORGSTRUCTURE_POSITION_EDIT,
                params : {
                  positionId : item.staffPosition.id
                }
              }"
            >
              {{ item.staffPosition.name }}
            </RouterLink>
          </template>
          <template #item.staffPosition.teamName="{item}">
            <RouterLink
              :to="{
                name : Names.R_ORGSTRUCTURE_COMPANY_TEAM,
                params : {
                  companyId : item.staffPosition.companyId,
                  teamId : item.staffPosition.teamId
                }
              }"
            >
              {{ item.staffPosition.teamName || item.staffPosition.teamDisplayName }}
            </RouterLink>
          </template>
          <template #item.user.fullName="{item}">
            <RouterLink
              v-if="item.user"
              :to="{
                name : Names.R_AUTHENTICATION_USER,
                params : { userId : item.user.id }
              }"
            >
              {{ item.user.fullName }}
            </RouterLink>
          </template>
          <template #item.actions="{item}">
            <VTooltip left>
              <template #activator="{ on, attrs }">
                <VBtn
                  icon
                  small
                  dark
                  color="red"
                  class="mx-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="unlinkStaffPosition(item, true)"
                >
                  <VIcon small>
                    fal fa-trash
                  </VIcon>
                </VBtn>
              </template>

              <span>Удаление</span>
            </VTooltip>
          </template>
        </VDataTable>
      </VCol>
    </VRow>

    <ConfirmDelete
      v-model="confirmDialog"
      title="Удалить"
      :text="confirmDialogText"
      @cancel="handleCancelDeleteLink"
      @submit="handleSubmitDelete"
    />
  </div>
</template>

<script>
import ConfirmDelete from '@front.backoffice/shared/src/components/ConfirmDelete.vue';

export default {
  name: 'UserCompanyHierarchy',
  components: { ConfirmDelete },
  inject: ['Names'],
  props: {
    staffPosition: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedStaffPosition: null,
      selectedFullStaffPosition: {},
      usersStaffPositions: [],
      chiefs: null,
      subordinates: null,
      connectedPeopleHeaders: [
        {
          text: 'ID',
          value: 'staffPosition.id',
          width: '80px',
        },
        {
          text: 'Штатная должность',
          value: 'staffPosition.name',
          width: '40%',
        },
        {
          text: 'Команда',
          value: 'staffPosition.teamName',
          width: '25%',

        },
        {
          text: 'ФИО',
          value: 'user.fullName',
          width: '25%',
        },
        {
          text: '',
          value: 'actions',
          align: 'right',
          sortable: false,
        },
      ],
      usersStaffPositionsHeaders: [
        {
          text: 'ID',
          value: 'id',
          width: '80px',
        },
        {
          text: 'Штатная должность',
          value: 'name',
          width: '40%',
        },
        {
          text: 'Команда',
          value: 'teamName',
          width: '50%',
        },
        {
          text: 'ФИО',
          value: 'user',
          width: '25%',
        },
        {
          text: 'Основная ШД',
          value: 'main',
          align: 'center',
          sortable: false,
        },
      ],
      confirmDialogText: '',
      confirmDialog: false,
      deleteLink: null,
    };
  },
  computed: {
    personId() {
      return this.$route.params.personId;
    },

    companyId() {
      return this.$route.params.companyId;
    },

    queryStaffPosition() {
      return this.$route.query.staffPosition;
    },

    userChiefs() {
      if (!this.chiefs) {
        return [];
      }
      let { chiefs } = this;
      if (this.selectedStaffPosition) {
        chiefs = chiefs.filter((sp) => sp.toStaffPosition.id === this.selectedStaffPosition);
      }
      return chiefs.map(({ user, link, fromStaffPosition }) => ({
        user: {
          ...user,
          fullName: `${user?.lastName ? user.lastName : ''}
          ${user?.firstName ? user.firstName : ''}
          ${user?.middleName ? user.middleName : ''}`,
        },
        link,
        staffPosition: fromStaffPosition,
      }));
    },

    userSubordinates() {
      if (!this.subordinates) {
        return [];
      }
      let { subordinates } = this;
      if (this.selectedStaffPosition) {
        subordinates = subordinates.filter((sp) => sp.fromStaffPosition.id === this.selectedStaffPosition);
      }
      return subordinates.map(({ user, link, toStaffPosition }) => ({
        user: {
          ...user,
          fullName: `${user?.lastName ? user.lastName : ''}
          ${user?.firstName ? user.firstName : ''}
          ${user?.middleName ? user.middleName : ''}`,
        },
        link,
        staffPosition: toStaffPosition,
      }));
    },
  },

  watch: {
    confirmDialog(val) {
      if (!val) {
        this.deleteLink = null;
        this.confirmDialogText = '';
      }
    },
    usersStaffPositions(staffPositions) {
      const staffPosition = staffPositions.find((sp) => sp.main);
      if (staffPosition && !this.selectedStaffPosition) {
        this.selectedStaffPosition = staffPosition.id;
      }
      if (staffPositions.length && !this.selectedStaffPosition) {
        this.selectedStaffPosition = staffPositions[0].id;
      }

      this.selectedFullStaffPosition = staffPositions.find((el) => el.id === this.selectedStaffPosition);
    },
    queryStaffPosition(queryStaffPosition) {
      this.selectedStaffPosition = queryStaffPosition;
    },
  },

  async created() {
    await this.fetch();
  },

  methods: {
    async fetch() {
      try {
        this.chiefs = null;
        this.subordinates = null;
        this.selectedStaffPosition = this.queryStaffPosition;
        const { staffPositions } = await this.$di.api.Orgstructure.getStaffPositionsByUser({ userId: this.personId });
        this.usersStaffPositions = this.staffPositionsCompanyFilter(staffPositions);
        const { result: connectedStaffPositions } = await this.$di.api.Orgstructure.connectedStaffPositions({
          staffPositionIds: staffPositions.map((item) => item.id),
        });

        const staffPositionIds = Array.from(connectedStaffPositions.reduce(
          (accumulator, { toStaffPositionId, fromStaffPositionId }) => {
            accumulator.add(toStaffPositionId);
            accumulator.add(fromStaffPositionId);
            return accumulator;
          }, new Set(),
        ));

        const staffPositionList = await this.$di.api.Orgstructure.staffPositionsByIds({ ids: staffPositionIds });

        const usersIds = Array.from(staffPositionList.staffPositions.reduce(
          (accumulator, { userId }) => {
            if (userId) {
              accumulator.add(userId);
            }
            return accumulator;
          }, new Set(),
        ));

        let usersList = [];
        if (usersIds.length) {
          usersList = await this.$di.api.Authentication.getUsers({ ids: usersIds });
        }

        const [subordinates, chiefs] = connectedStaffPositions.reduce(
          (accumulator, link) => {
            const toStaffPosition = staffPositionList.staffPositions.find(
              (sp) => sp.id === link.toStaffPositionId,
            );
            const fromStaffPosition = staffPositionList.staffPositions.find(
              (sp) => sp.id === link.fromStaffPositionId,
            );
            if (toStaffPosition.userId !== this.personId || fromStaffPosition.userId === this.personId) {
              accumulator[0].push(
                {
                  user: usersList.users.find((user) => user.id === toStaffPosition.userId),
                  link,
                  fromStaffPosition,
                  toStaffPosition,
                },
              );
            }
            if (fromStaffPosition.userId !== this.personId || toStaffPosition.userId === this.personId) {
              accumulator[1].push(
                {
                  user: usersList.users.find((user) => user.id === fromStaffPosition.userId),
                  link,
                  fromStaffPosition,
                  toStaffPosition,
                },
              );
            }
            return accumulator;
          }, [[], []],
        );

        this.subordinates = subordinates;
        this.chiefs = chiefs;
      } catch (error) {
        this.$di.notify.errorHandler(error);
        this.chiefs = [];
        this.subordinates = [];
      }
    },
    async refreshData() {
      try {
        await this.fetch();
      } catch (error) {
        this.$di.notify.errorHandler(error);
      }
    },

    handleSubmitDelete() {
      if (this.deleteLink) {
        this.handleSubmitDeleteLink();
      }
    },

    async handleSubmitDeleteLink() {
      try {
        await this.$di.api.Orgstructure.unlinkStaffPositions(this.deleteLink);
        await this.refreshData();
      } catch (error) {
        this.$di.notify.errorHandler(error);
      } finally {
        this.handleCancelDeleteLink();
      }
    },

    staffPositionsCompanyFilter(positions) {
      return positions.filter((el) => el.companyId === this.companyId);
    },

    handleCancelDeleteLink() {
      this.confirmDialog = false;
    },

    unlinkStaffPosition(sp, userIsChief) {
      this.deleteLink = sp.link;
      // eslint-disable-next-line max-len,vue/max-len
      this.confirmDialogText = `Удалить ${sp.staffPosition.name} из списка ${userIsChief ? 'подчиненных' : 'начальников'}?`;
      this.confirmDialog = true;
    },
  },
};

</script>
